import Link from "next/link";
import Image from "next/image";
import React from "react";

import Logo from "@assets/img/logo/nuviad_logo_final.svg";

import MobileMenus from "../layout/headers/mobile-menus";

interface SidebarProps {
    sidebarOpen: any;
    setSidebarOpen: any;
}

const Sidebar = (props: SidebarProps) => {
    const { sidebarOpen, setSidebarOpen } = props;

    return (
        <>
            <div
                className={`offcanvas__area ${
                    sidebarOpen ? "offcanvas-opened" : ""
                }`}
            >
                <div className="offcanvas__wrapper">
                    <div className="offcanvas__close">
                        <button
                            className="offcanvas__close-btn offcanvas-close-btn"
                            onClick={() => setSidebarOpen(false)}
                        >
                            <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M11 1L1 11"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M1 1L11 11"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="offcanvas__content">
                        <div className="offcanvas__top mb-50 d-flex justify-content-between align-items-center">
                            <div className="offcanvas__logo logo">
                                <Link href="/">
                                    <Image
                                        src={Logo}
                                        loading="eager"
                                        alt="logo"
                                        width={150}
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="mobile-menu fix d-lg-none" />
                        <div className="tp-mobile-menu-pos mean-container d-lg-none">
                            <MobileMenus
                                onClickHandler={() => setSidebarOpen(false)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`body-overlay ${sidebarOpen && "opened"}`}
                onClick={() => setSidebarOpen(false)}
            />
        </>
    );
};

export default Sidebar;
