// #region Global Imports
import React from "react";
import { Field, FastField, getIn } from "formik";
import { Form } from "react-bootstrap";
// #endregion Global Imports

// #region Local Imports
import { TextInputFieldProps } from "./TextInputField";
// #endregion Local Imports

const TextInputField: React.FunctionComponent<TextInputFieldProps> = ({
    name,
    label,
    placeholder,
    hint,
    inputProps = {},
    fastField = false,
    disabled = false,
    type = "text",
    onChange,
    isRequired = false,
}): JSX.Element => {
    const render = ({
        field,
        form: { touched, errors, setFieldValue },
    }: {
        field: any;
        form: any;
    }) => {
        const error = getIn(errors, name);
        const isTouched = getIn(touched, name);

        const handleChange = (e: { target: { value: any } }) => {
            setFieldValue(name, e.target.value);
            if (onChange) {
                onChange(e.target.value);
            }
        };

        const fieldValue = field.value;

        return (
            <Form.Group controlId={name}>
                {label && (
                    <Form.Label>{isRequired ? `${label} *` : label}</Form.Label>
                )}
                <Form.Control
                    {...inputProps}
                    custom={true}
                    name={name}
                    value={fieldValue}
                    onChange={handleChange}
                    placeholder={isRequired ? `${placeholder} *` : placeholder}
                    className={disabled ? "opacity-50" : ""}
                    isInvalid={isTouched && !!error}
                    disabled={disabled}
                    type={type}
                />
                <Form.Text muted={true}>{hint}</Form.Text>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Form.Group>
        );
    };

    if (fastField) {
        return <FastField name={name}>{render}</FastField>;
    }
    return <Field name={name}>{render}</Field>;
};

export { TextInputField };
