import React from "react";
import Link from "next/link";
import { useTranslation } from "next-export-i18n";

import HomeIcon from "@/src/svg/home-icon";

const NavMenu = () => {
    const { t } = useTranslation();

    const menuData = [
        {
            id: 1,
            mega_menu: false,
            has_dropdown: false,
            has_arrow: false,
            home_icon: <HomeIcon />,
            title: t("navbar.menu_items.home"),
            link: "/",
            active: "",
        },
        {
            id: 2,
            mega_menu: false,
            has_dropdown: false,
            has_arrow: false,
            title: t("navbar.menu_items.platform"),
            link: "/platform",
            active: "",
        },
        {
            id: 3,
            mega_menu: false,
            has_dropdown: false,
            has_arrow: false,
            title: t("navbar.menu_items.testimonials"),
            link: "/testimonials",
            active: "",
        },
        {
            id: 4,
            mega_menu: false,
            has_dropdown: false,
            has_arrow: false,
            title: t("navbar.menu_items.contact"),
            link: "/contact",
            active: "",
        },
    ];

    return (
        <>
            <ul>
                {menuData.map((menu_item, i) => (
                    <li
                        key={i}
                        className={`${
                            menu_item?.has_dropdown && "has-dropdown"
                        }`}
                    >
                        <Link href={menu_item.link}>
                            <div className="d-inline p-relative">
                                {menu_item.title}
                            </div>{" "}
                            {menu_item.has_arrow && (
                                <i className="fa-solid fa-arrow-down fa-xs" />
                            )}
                        </Link>
                        {menu_item.has_dropdown && (
                            <ul className="submenu">
                                {(menu_item as any).sub_menus.map(
                                    (sub_menu: any, i: number) => (
                                        <li key={i}>
                                            <Link href={sub_menu.link}>
                                                <span>{sub_menu.title}</span>
                                            </Link>
                                        </li>
                                    )
                                )}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </>
    );
};

export default NavMenu;
