import React from "react";

const HomeIcon = () => {
    return (
        <>
            <svg
                width="11"
                height="11"
                viewBox="0 0 12 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.04088 0L0.535156 4.125V11H4.26484V8.59381C4.26484 7.64165 5.05698 6.87506 6.04088 6.87506C7.02477 6.87506 7.81692 7.64165 7.81692 8.59381V11H11.5466V4.125L6.04088 0Z"
                    fill="var(--tp-theme-secondary)"
                />
            </svg>
        </>
    );
};

export default HomeIcon;
