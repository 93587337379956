import React, { useState } from "react";
import Link from "next/link";
import { useTranslation } from "next-export-i18n";

import HomeIcon from "@/src/svg/home-icon";

interface MobileMenusProps {
    onClickHandler: () => void;
}

const MobileMenus = (props: MobileMenusProps) => {
    const [navTitle, setNavTitle] = useState("");
    const { t } = useTranslation();
    const { onClickHandler } = props;

    const menuData = [
        {
            id: 1,
            mega_menu: false,
            has_dropdown: false,
            home_icon: <HomeIcon />,
            title: t("navbar.menu_items.home"),
            link: "/",
            active: "",
        },
        {
            id: 2,
            mega_menu: false,
            has_dropdown: false,
            title: t("navbar.menu_items.platform"),
            link: "/platform",
            active: "",
        },
        {
            id: 3,
            mega_menu: false,
            has_dropdown: false,
            title: t("navbar.menu_items.testimonials"),
            link: "/testimonials",
            active: "",
        },
        {
            id: 4,
            mega_menu: false,
            has_dropdown: false,
            title: t("navbar.menu_items.contact"),
            link: "/contact",
            active: "",
        },
    ];

    return (
        <>
            <nav className="mean-nav">
                <ul>
                    {menuData.map((menu, i) => (
                        <React.Fragment key={i}>
                            {menu.has_dropdown && (
                                <li className="has-dropdown">
                                    <Link href={menu.link}>{menu.title}</Link>
                                    <ul
                                        className="submenu"
                                        style={{
                                            display:
                                                navTitle === menu.title
                                                    ? "block"
                                                    : "none",
                                        }}
                                    >
                                        {(menu as any).sub_menus?.map(
                                            (sub: any, i: number) => (
                                                <li key={i}>
                                                    <Link href={sub.link}>
                                                        {sub.title}
                                                    </Link>
                                                    <i className="fa-solid fa-arrow-down" />
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </li>
                            )}
                            {!menu.has_dropdown && (
                                <li>
                                    <Link
                                        href={menu.link}
                                        onClick={onClickHandler}
                                    >
                                        {menu.title}
                                    </Link>
                                </li>
                            )}
                        </React.Fragment>
                    ))}
                    <li>
                        <Link
                            href={process.env.NEXT_PUBLIC_DASHBOARD_URL!}
                            target="_blank"
                        >
                            <i className="fa-light fa-right-to-bracket pr-10 header_log-in-icon" />
                            {t("navbar.btns.log_in")}
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default MobileMenus;
