import "isomorphic-unfetch";
import queryString from "query-string";

const BaseUrl = process.env.NEXT_PUBLIC_API_URL;
const AppId = process.env.NEXT_PUBLIC_APP_ID;
export const http = {
    Request: async <A>(
        methodType: string,
        url: string,
        params?: any,
        payload?: any,
        needStatus?: boolean,
        signal?: AbortSignal
    ): Promise<{ status: number; data: A }> => {
        return new Promise((resolve, reject) => {
            const query = params
                ? `?${queryString.stringify({
                      ...params,
                  })}`
                : "";

            const body = JSON.stringify(payload);
            const headers = {
                "content-type": "application/json",
                app_id: AppId!,
            };

            fetch(`${BaseUrl}${url}${query}`, {
                body,
                cache: "no-cache",
                headers,
                method: `${methodType}`,
                signal,
            })
                .then(async (response) => {
                    if (needStatus) {
                        return response.json().then((data) => {
                            resolve({
                                status: response.status,
                                data,
                            });
                        });
                    }
                    if (response.status === 200) {
                        return response.json().then(resolve);
                    }
                    return reject(response);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
};
