import Head from "next/head";

const SEO = ({ pageTitle }: any) => (
    <>
        <Head>
            <title>{pageTitle && `${pageTitle}`}</title>
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta name="robots" content="noindex, follow" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <link rel="icon" href="assets/img/logo/favicon.png" />
        </Head>
    </>
);

export default SEO;
