import { http } from "./API/http";

export function postItems(
    url: string,
    data: any,
    params?: any,
    needStatus?: boolean,
    signal?: AbortSignal
) {
    return http.Request<any>("POST", url, params, data, needStatus, signal);
}
