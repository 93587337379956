import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import NavMenu from "./nav-menu";
import { useTranslation } from "next-export-i18n";

import useSticky from "@/src/hooks/use-sticky";
import Sidebar from "@/src/modals/sidebar";
import HomeIcon from "@/src/svg/home-icon";
import Logo from "@assets/img/logo/nuviad_logo_final.svg";
import HamburgerBtn from "@/src/svg/hamburger-btn";

const Header = () => {
    const { sticky } = useSticky();
    const langToggleRef = useRef<any>(null);
    const [isLangListOpen, setIsLangListOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (langToggleRef.current) {
            const handleClickOutside = (e: any) => {
                if ((langToggleRef.current as any).contains(e.target)) {
                    setIsLangListOpen(!isLangListOpen);
                } else {
                    setIsLangListOpen(false);
                }
            };
            window.addEventListener("click", handleClickOutside);
            return () => {
                window.removeEventListener("click", handleClickOutside);
            };
        }
    }, [isLangListOpen, langToggleRef]);

    useEffect(() => {
        if (langToggleRef.current) {
            const handleClickOutside = (e: any) => {
                if (langToggleRef.current.contains(e.target)) {
                    setIsLangListOpen(!isLangListOpen);
                } else {
                    setIsLangListOpen(false);
                }
            };
            window.addEventListener("click", handleClickOutside);
            return () => {
                window.removeEventListener("click", handleClickOutside);
            };
        }
    }, [isLangListOpen, langToggleRef]);

    return (
        <>
            <header className="tp-header-area tp-header-height p-relative">
                <div
                    id="header-sticky"
                    className={`tp-header-bottom header__sticky p-relative ${
                        sticky && "tp-header-sticky"
                    }`}
                >
                    <div className="header p-relative">
                        <div className="container-fluid gx-0">
                            <div className="row gx-0 align-items-center appbar-container">
                                <div className="appbar">
                                    <div
                                        className="header_hamburger tp-header-hamburger-btn offcanvas-open-btn"
                                        style={{
                                            backgroundImage: `url(/assets/img/icon/header-hamburger-shape.png)`,
                                            zIndex: 10,
                                        }}
                                        onClick={() => setSidebarOpen(true)}
                                    >
                                        <button className="hamburger-btn">
                                            <span>
                                                {" "}
                                                <HamburgerBtn />
                                            </span>
                                        </button>
                                    </div>
                                    <div className="tp-header-main-left d-flex align-items-center p-relative">
                                        <div className="tp-header-logo">
                                            <Link href="/">
                                                <Image
                                                    src={Logo}
                                                    loading="eager"
                                                    alt="theme-pure"
                                                    height={40}
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-6 col-xl-6 d-none d-xl-block">
                                    <div className="tp-main-menu-area counter d-flex align-items-center">
                                        <div className="tp-main-menu menu-icon">
                                            <span className="header-icon">
                                                <HomeIcon />
                                            </span>
                                            <nav id="tp-mobile-menu">
                                                <NavMenu />
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-xl-4">
                                    <div className="d-flex align-items-center justify-content-xl-end">
                                        <div className="d-none d-xl-block pl-20">
                                            <Link
                                                className="tp-btn-header header_log-in-btn"
                                                href={
                                                    process.env
                                                        .NEXT_PUBLIC_DASHBOARD_URL!
                                                }
                                                target="_blank"
                                            >
                                                <i className="fa-light fa-right-to-bracket pr-10 header_log-in-icon" />
                                                {t("navbar.btns.log_in")}
                                            </Link>
                                        </div>
                                        <div className="tp-header-btn d-none d-xl-block pl-20">
                                            <Link
                                                className="tp-btn"
                                                href="/contact"
                                            >
                                                {t("navbar.btns.contact_us")}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Sidebar
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />
        </>
    );
};

export default Header;
