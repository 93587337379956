import React, { useRef, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "next-export-i18n";

import footer_logo from "@assets/img/logo/nuviad_logo_on_black.svg";
import { CopyRight } from "@/src/common/social-links";
import { TextInputField } from "@/src/components/formik/text-input";
import { postItems } from "@/src/services";

const recaptchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_KEY || "";

const Footer = () => {
    const [contactMsg, setContactMsg] = useState("");
    const [recaptchaNeeded, setRecaptchaNeeded] = useState(false);
    const recaptchaRef = useRef<any>();
    const { t } = useTranslation();

    const footerContent = {
        info: t("footer.text"),
        map: "https://www.google.com/maps/search/Ha-Tamar+St+3,+Yokne'am+Illit,+2062002,+Israel/@32.6608571,35.1035431,17z/data=!3m1!4b1",
        address: (
            <>
                Ha-Tamar St 3 <br /> Yokne'am Illit, Israel.
            </>
        ),
        mail_2: "info@nuviad.com",
        mail_phone: <> info@nuviad.com</>,

        service_links: [
            { text: t("footer.service_links.home"), link: "/" },
            { text: t("footer.service_links.platform"), link: "/platform" },
            {
                text: t("footer.service_links.case_studies"),
                link: "/testimonials",
            },
            { text: t("footer.service_links.contact"), link: "/contact" },
        ],
    };

    const { info, map, address, mail_2, mail_phone, service_links } =
        footerContent;

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required(t("contact.form.validation_error.required")),
        email: yup
            .string()
            .email(t("contact.form.validation_error.email"))
            .required(t("contact.form.validation_error.required")),
    });

    const onSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        const { name, email, message } = values;

        try {
            formikHelpers.setSubmitting(true);
            const captcha = await recaptchaRef.current!.executeAsync();
            await postItems("/homepage/contact_us", {
                name,
                email,
                message,
                captcha,
            });

            setContactMsg(t("contact.form.message"));
            formikHelpers.setSubmitting(false);
        } catch (error: any) {
            formikHelpers.setStatus("Request error!");
            formikHelpers.setSubmitting(false);
        }
    };

    const handleChange = () => {
        if (!recaptchaNeeded) {
            setRecaptchaNeeded(true);
        }
    };

    return (
        <>
            <footer className="footer tp-footer-area p-relative">
                <div className="container container-large">
                    <div className="tp-footer-main-area">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="tp-footer-widget tp-footer-col-1">
                                    <div className="tp-footer-logo">
                                        <a href="index.html">
                                            {" "}
                                            <Image
                                                src={footer_logo}
                                                alt="theme-pure"
                                                width={140}
                                            />
                                        </a>
                                    </div>
                                    <div className="tp-footer-widget-content">
                                        <div className="tp-footer-info">
                                            <p>{info}</p>
                                            <div className="tp-footer-main-location">
                                                <a target="_blank" href={map}>
                                                    {" "}
                                                    <i className="fa-sharp fa-light fa-location-dot" />
                                                    {address}
                                                </a>
                                            </div>
                                            <div className="tp-footer-main-mail">
                                                <a href={`mailto:${mail_2}`}>
                                                    <i className="fa-light fa-envelope" />
                                                    {mail_phone}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8 col-md-12">
                                <div className="tp-footer-widget tp-footer-col-2">
                                    <h3 className="tp-footer-widget-title">
                                        {t("footer.navigate")}
                                    </h3>
                                    <div className="tp-footer-widget-content">
                                        <div className="d-flex">
                                            <ul className="w-50">
                                                {service_links
                                                    .slice(0, 2)
                                                    .map((link, i) => (
                                                        <li key={i}>
                                                            <Link
                                                                href={link.link}
                                                            >
                                                                {link.text}
                                                            </Link>
                                                        </li>
                                                    ))}
                                            </ul>
                                            <ul className="w-50">
                                                {service_links
                                                    .slice(2)
                                                    .map((link, i) => (
                                                        <li key={i}>
                                                            <Link
                                                                href={link.link}
                                                            >
                                                                {link.text}
                                                            </Link>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-5 col-md-6">
                                <div className="tp-footer-widget tp-footer-col-4">
                                    <h3 className="tp-footer-widget-title">
                                        {t("footer.quick_contact")}
                                    </h3>
                                    {contactMsg ? (
                                        <div className="tp-footer-form">
                                            {contactMsg}
                                        </div>
                                    ) : (
                                        <Formik
                                            validationSchema={validationSchema}
                                            onSubmit={onSubmit}
                                            initialValues={{
                                                email: "",
                                                name: "",
                                                message: "",
                                            }}
                                            enableReinitialize={true}
                                        >
                                            {({
                                                handleSubmit,
                                                isSubmitting,
                                                status,
                                            }: any) => (
                                                <div className="tp-footer-form">
                                                    <div className="tp-footer-input-field">
                                                        <TextInputField
                                                            name="email"
                                                            placeholder="Email"
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="tp-footer-input-field">
                                                        <TextInputField
                                                            name="name"
                                                            placeholder="Name"
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="tp-footer-input-field mt-40">
                                                        <TextInputField
                                                            name="message"
                                                            placeholder="Message"
                                                            inputProps={{
                                                                as: "textarea",
                                                            }}
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="small captcha-msg">
                                                        This site is protected
                                                        by reCAPTCHA. reCAPTCHA
                                                        is protected by the
                                                        Google{" "}
                                                        <a
                                                            href="https://policies.google.com/privacy"
                                                            target="_blank"
                                                        >
                                                            <b>
                                                                Privacy Policy
                                                            </b>
                                                        </a>{" "}
                                                        and{" "}
                                                        <a
                                                            href="https://policies.google.com/terms"
                                                            target="_blank"
                                                        >
                                                            <b>Terms of use</b>
                                                        </a>
                                                        .
                                                    </div>
                                                    {recaptchaNeeded && (
                                                        <ReCAPTCHA
                                                            // @ts-ignore
                                                            ref={recaptchaRef}
                                                            sitekey={
                                                                recaptchaKey
                                                            }
                                                            size="invisible"
                                                        />
                                                    )}
                                                    <div className="d-flex justify-content-end mt-20">
                                                        <button
                                                            className="tp-footer-form-submit-btn"
                                                            onClick={
                                                                handleSubmit
                                                            }
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                        >
                                                            {isSubmitting && (
                                                                <Spinner animation="grow" />
                                                            )}
                                                            {t("footer.send")}
                                                        </button>
                                                    </div>
                                                    {status && (
                                                        <div className="text-center text-danger">
                                                            {status}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </Formik>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tp-footer-copyright-area p-relative">
                        <div className="row">
                            <div className="col-md-12 col-lg-6">
                                <div className="tp-footer-copyright-inner">
                                    <p>
                                        <CopyRight />{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
